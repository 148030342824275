body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 10pt;
    color: #444;
    margin: 0px;
}

a {
    text-decoration: none;
}

#header {
    margin-left:4px;
    background-color:#f7d639;
    width: 982px;
}

#header td{
    padding:0px;
}

#header tr {
    padding:0px;
}

#header tr.c1 .mid {
    vertical-align: bottom;
    border-bottom: 3px solid white;
    border-right: 3px solid white;
    color: white;
}

#header .top-links {
    padding-top: 4px;
    font-size: 10px;
    letter-spacing: 0.07em;
    text-align: right;
    width: 488px;
}

#header .top-links a {
    color: #BB3C27;
}

#header .logo {
    max-width:245px;
    width:245px;
    overflow:hidden;
    vertical-align:top;
}

div.textlogo {
    height: 53px;
    width: 245px;
    vertical-align: middle;
    color: white;
    background-color: #7b9c1f;
    font-family: HelveticaNeue-CondensedBlack, Impact, "Arial Narrow", sans-serif;
    font-size: 34px;
    padding: 0px 0px 0px 7px;
    overflow: hidden;
}


#header .logo img {
    border:none;
    position:relative;
    float:left;
    _position: inline;
    _float: none;
}

#header .logo #podlogom img{
    border-top: 4px solid white;
}

#header .slogan {
    border-bottom: 3px solid white;
    width:245px;
    overflow:hidden;
    height:81px;
    vertical-align: bottom;
}

#header .slogan img {
    position:relative;
    top:3px;
    _top:0px;
}

#navigator {
    width: 240px;
    border-top: 3px solid white;
    background-color: #a7c834;
    vertical-align: top;
}

#navigator .caption {
    padding: 5px 5px 0px 10px;
    font-family: HelveticaNeue-CondensedBlack, Impact, "Arial Narrow", sans-serif;
    font-size: 12pt;
    text-transform: uppercase;
    letter-spacing: 1pt;
    color: white;
}

#navigator .smallmap {
    margin-left: 25px;
}

#search {
    width: 200px;
    border:none;
    margin:0px 0px 0px 6px;
    display:block;
}

#search fieldset {
    border:0px;
    padding:0px;
    margin:0px;
}

#search input {
    border: 3px solid white;
    background-color: #f7d135;
    color: white;
    font-size: 8pt;
    _width: 30px;
}

#search input.box {
    border: 3px solid white;
    background-color: white;
    _border-top: 5px solid white;
    font-size: 10px;
    letter-spacing: 1pt;
    width: 150px;
    color: #ef9800;
}

#maintwo {
    //float: left;
    display: flex;
    flex-wrap: wrap;
    #mainleft {
        margin-right: 4px;
    }
}

#before_main {
    width: 738px;
    overflow: hidden;
    margin: 4px 0 4px 4px;
    max-height: 350px;
}

#main {
    width: 986px;
}

#mainleft {
    width: 494px;
    margin-left:4px;
    _margin-left:2px;
    margin-top: 4px;
    float: left;
    vertical-align: top;
    overflow: visible;
    padding:0px;
}

#mainmid {
    background-color: white;
    width: 240px;
    margin-left:4px;
    margin-top: 4px;
    float: left;
    vertical-align: top;
    overflow: hidden;
    padding:0px;
}

#mainright {
    background-color: white;
    width: 240px;
    border-left:4px solid white;
    border-top: 4px solid white;
    float: left;
    vertical-align: top;
    overflow: hidden;
    padding:0px;
    display: block;
}

.blok_absright {
    position: absolute;
    right: 4px;
    bottom: 3px;
    display: block;
}

.blokoranzovy {
    font-size: 9pt;
    margin-bottom: 3px;
    background-color: #f7d135;
    /*position: relative;*/
}

.blokoranzovy .caption {
    font-family: HelveticaNeue-CondensedBlack, Impact, "Arial Narrow", sans-serif;
    font-size: 12pt;
    text-transform: uppercase;
    letter-spacing: 1pt;
    display: block;
    background-color: #f7d135;
    color: white;
    padding: 4px 0px 2px 4px;
    border-bottom: 1px solid white;
}

.blokoranzovy .comment {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 8pt;
    letter-spacing: normal;
    text-transform: none;
    /*position: absolute;*/
    right: 5px;
    top: 1em;
}

.blokoranzovy fieldset {
    padding: 0px;
    margin: 0px;
    border: none;
}

.blokoranzovy a {
    color: #333;
}
.blokoranzovy a:hover {
    color: #bb3c27;
}
.blokoranzovy_ul {
    background-color: #f7d135;
    padding: 0px;
    margin: 0px;
}

.blokoranzovy_ul li {
    position: relative;
    list-style: none;
    border-bottom: 1px solid white;
    padding: 4px 4px 3px 4px;
    color: #bb3c27;
}
.blokoranzovy_ul li ul {
    padding: 3px 0px 0px 0px;
    margin: 0px;
}
.blokoranzovy_ul li ul li {
    border: none;
    padding: 0px;
    color: #333;
    font-size: 9pt;
}
.blokoranzovy_ul span.highlight {
    color: #bb3c27;
}



.blokoranzovy_ul li p, .blokmodry li p, .blokcerveny li p, .blokzeleny li p {
    padding: 2px;
    margin: 0px 0px 0px 0px;
}

.white {
    color: white;
}

.black {
    color: #333;
}

.red {
    color: #bb3c27;
}

.small {
    font-size: 0.9em;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.blokcerveny {
    font-size: 9pt;
    margin-bottom: 3px;
    background-color: #D3604D;
}

.blokcerveny .caption {
    font-family: HelveticaNeue-CondensedBlack, Impact, "Arial Narrow", sans-serif;
    font-size: 12pt;
    text-transform: uppercase;
    letter-spacing: 1pt;
    display: block;
    color: white;
    padding: 4px 0px 2px 4px;
    border-bottom: 1px solid white;
}

.blokcerveny .comment {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 8pt;
    letter-spacing: normal;
    text-transform: none;
    /*position: absolute;*/
    right: 5px;
    top: 1em;
}
.blokcerveny .note {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 9pt;
    letter-spacing: normal;
    text-transform: none;
    right: 5px;
    top: 1em;
    color: #000;
}

.blokcerveny a {
    color: #fff;
}
.blokcerveny a:hover {
    color: #F7D135;
}
.blokcerveny_ul {
    background-color: #D3604D;
    padding: 0px;
    margin: 0px;
}

.blokcerveny_ul li {
    position: relative;
    list-style: none;
    border-bottom: 1px solid white;
    padding: 4px 4px 3px 4px;
    color: #000;
}
.blokcerveny_ul li ul {
    padding: 3px 0px 0px 0px;
    margin: 0px;
}
.blokcerveny_ul li ul li {
    border: none;
    padding: 0px;
    color: #000;
    font-size: 8pt;
}
.blokcerveny_ul span.highlight {
    color: #f7d135;
}



.blokmodry {
    font-size: 9pt;
    margin-bottom: 3px;
    background-color: #5B9EE3;
    display: block;
    position: relative;
}

.blokmodry .caption {
    font-family: HelveticaNeue-CondensedBlack, Impact, "Arial Narrow", sans-serif;
    font-size: 12pt;
    text-transform: uppercase;
    letter-spacing: 1pt;
    display: block;
    background-color: #5B9EE3;
    color: white;
    padding: 5px 0px 2px 5px;
    border-bottom: 1px solid white;
}

.blokmodry .caption .comment {
font-family: Arial, Helvetica, sans-serif;
	font-size: 8pt;
	text-transform: none;
	letter-spacing: normal;
	font-weight: normal;
}

.blokmodry a {
    color: #fff;
}
.blokmodry a:hover {
    color: rgb(25, 68, 111);
}
.blokmodry_ul {
    background-color: #5B9EE3;
    padding: 0px;
    margin: 0px;
}

.blokmodry_ul li {
    position: relative;
    list-style: none;
    border-bottom: 1px solid white;
    padding: 4px 4px 3px 4px;
    color: rgb(25, 68, 111);;
}
.blokmodry_ul li ul {
    padding: 3px 0px 0px 0px;
    margin: 0px;
}
.blokmodry_ul li ul li {
    border: none;
    padding: 0px;
    color: rgb(25, 68, 111);;
    font-size: 8pt;
}
.blokmodry_ul span.highlight {
    color: rgb(25, 68, 111);
}


/* zeleny */

.blokzeleny {
    font-size: 9pt;
    margin-bottom: 3px;
    background-color: #a7c834;  /* svetlozelena: #c4e75b; tmavozelena: #7e9920;*/
    /*position: relative;*/
}

.blokzeleny .caption {
    font-family: HelveticaNeue-CondensedBlack, Impact, "Arial Narrow", sans-serif;
    font-size: 12pt;
    text-transform: uppercase;
    letter-spacing: 1pt;
    display: block;
    background-color: #a7c834;
    color: white;
    padding: 4px 0px 2px 4px;
    border-bottom: 1px solid white;
}

.blokzeleny .comment {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 8pt;
    letter-spacing: normal;
    text-transform: none;
    /*position: absolute;*/
    right: 5px;
    top: 1em;
}

.blokzeleny fieldset {
    padding: 0px;
    margin: 0px;
    border: none;
}

.blokzeleny a {
    color: white;
}
.blokzeleny a:hover {
    color: #444;
}
.blokzeleny_ul {
    background-color: #a7c834;
    padding: 0px;
    margin: 0px;
}

.blokzeleny_ul li {
    position: relative;
    list-style: none;
    border-bottom: 1px solid white;
    padding: 4px 4px 3px 4px;
    color: #444;
}
.blokzeleny_ul li ul {
    padding: 3px 0px 0px 0px;
    margin: 0px;
}
.blokzeleny_ul li ul li {
    border: none;
    padding: 0px;
    color: #444;
    font-size: 9pt;
}
.blokzeleny_ul span.highlight {
    color: #444;
}


.blokzeleny .markdown p img, .blokmodry .markdown p img, .blokoranzovy .markdown p img, .blokcerveny .markdown p img   {
    padding: 0;
    max-width: 228px;
    border-radius: inherit;
}




.oznam_1, .oznam_oranzovy {
    margin: 10px 0px 10px 0px;
    padding: 5px;
    background-color: #f8e7a1;
    border: 2px solid #f5b335;
    color: #000;
}

.oznam_1 p {
    margin-bottom: 0;
}

.oznam_1 a {
    color: #ba3d27 !important;
}


.oznam_cerveny {
    margin: 10px 0px 10px 0px;
    padding: 5px;
    background-color: #ffb6aa;
    border: 2px solid #ba3d27;
    color: #000;
    font-weight: bold;
}

.hide {
    display: none;
}


.btnNew.big {
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    padding: 10px 20px;
    line-height: 42px;
    text-shadow: 0px 1px 0px rgba(255,255,255,0.3);


    color: #333;
    background: -moz-linear-gradient(top, #eeeeee 0%, #bbbbbb);
    background: -webkit-gradient(linear, left top, left bottom, from(#eeeeee), to(#bbbbbb));
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#bbbbbb');
    border: 1px solid #666;
}

.btnNew.orange {
    color: #bb3d27;
    background: -moz-linear-gradient(top, #f7d739 0%, #f5b235);
    background: -webkit-gradient(linear, left top, left bottom, from(#f7d739), to(#f5b235));
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7d739', endColorstr='#f5b235');
    border: 1px solid #cc942d;
}


.btnTooltip.gray {
    font-size: 1.1em;
    color: #aaa;
}

.ui-widget {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

.shortcodes.inline-image {
    border-radius: 3px;
    margin: 2em 0;
}

.shortcodes.inline-image .caption p, .shortcodes.inline-gallery .caption p {
    margin: 0 0 0.33em 0;
    font-size: 0.8em;
    color: #999;
}

.shortcodes.inline-gallery {
    display: inline-block;
}

.shortcodes.inline-gallery div.img {
    display: inline-block;
}

.shortcodes.inline-gallery div.img img {
    max-width: 75px;
    max-height: 75px;
}