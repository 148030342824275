@import "./node_modules/@fortawesome/fontawesome-pro/less/_variables.less";
@import "./node_modules/@fortawesome/fontawesome-pro/less/_mixins.less";
@import "./node_modules/@fortawesome/fontawesome-pro/less/_core.less";
@import "./node_modules/@fortawesome/fontawesome-pro/less/_larger.less";
@import "./node_modules/@fortawesome/fontawesome-pro/less/_fixed-width.less";
@import "./node_modules/@fortawesome/fontawesome-pro/less/_list.less";
@import "./node_modules/@fortawesome/fontawesome-pro/less/_bordered-pulled.less";
@import "./node_modules/@fortawesome/fontawesome-pro/less/_animated.less";
@import "./node_modules/@fortawesome/fontawesome-pro/less/_rotated-flipped.less";
@import "./node_modules/@fortawesome/fontawesome-pro/less/_stacked.less";
@import "./node_modules/@fortawesome/fontawesome-pro/less/_icons.less";
@import "./node_modules/@fortawesome/fontawesome-pro/less/_screen-reader.less";

@fa-font-path: "webfonts";
@fa-font-display: block;

@import "./node_modules/@fortawesome/fontawesome-pro/less/brands.less";
//@import "./node_modules/@fortawesome/fontawesome-pro/less/solid.less";

/* Inline woff files */

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 900;
	font-display: @fa-font-display;
	src: data-uri('./node_modules/@fortawesome/fontawesome-pro/webfonts/fa-solid-900.woff2') format('woff2');
}

.fa,
.fas {
	font-family: 'Font Awesome 5 Pro';
	font-weight: 900;
}