@import "includes/_variables";
@import "includes/_fa";
@import "includes/_libs";

// Variables
@c_primary: @c_hiking_green;
@c_primary_hover: @c_hiking_green_dark;

@import "includes/shortcodes";
@import "includes/crmLogin";
@import "includes/mailer";
@import "includes/obrazky";

// jquery
@import "jquery-ui/jquery-ui.min.css";
@import "jquery-ui/jquery-ui.structure.css";
@import "jquery-ui/jquery-ui.theme.css";

// To be removed ASAP
@import "templates/main";
@import "includes/article";
@import "includes/article_edit";
@import "../public/common/bootstrap-markdown/css/bootstrap.min.css";

@font_sans_condensed: "Roboto Condensed", "Arial Narrow", sans-serif;
@font_sans: Roboto, 'Helvetica-Neue', Helvetica, Arial, sans-serif;
@font_helvetica: "HelveticaNeue-CondensedBlack", "Roboto Condensed", "Arial Narrow", sans-serif;
@family_luminsans: 'Lumin Sans', sans-serif;

.h3_legacy {
	font-family: @font_sans !important;

	img {
		display: initial;
	}

	#articleview {
		font-size: 14px;
		line-height: 1.5;
	}

	.article-edit-form {
		button {

		}
	}

	form {
		input[type=password], textarea, input[type=text] {
			border: 1px solid #ccc;
			padding: 5px;
		}
	}
}

.obrazky-add {
	background-color: white;
	border: 1px solid hsla(0, 0%, 50%, 0.5);
	padding: 5px 10px;
	.h3_root__dark & {
		background-color: hsl(0, 0%, 13%);
	}
	.files {
		line-height: initial;
		background-color: white;
		font-family: @family_luminsans;
		.h3_root__dark & {
			background-color: hsl(0, 0%, 13%);
		}
		.preview {
			padding: 0;
			margin: 0;
		}
		.name {
			padding-left: 10px;
			.filename {
				display: none;
			}
			.hkg-geo-location {
				display: block;
			}

		}
	}

	.well {
		min-height:initial;
		padding:initial;
		margin-bottom:initial;
		background-color:initial;
		border:initial;
		border-radius:initial;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.dropzone {
		background-color: white;
		padding: 10px 0;
		.fileinput-button {
			img {
				display: inline;
				.h3_root__dark & {
					display: none;
				}
			}
		}
		.h3_root__dark & {
			background-color: hsl(0, 0%, 13%);
		}
	}

	input {
		background-color: white;
		.h3_root__dark & {
			background-color: hsl(0, 0%, 13%);
		}
	}
}

.notification {
	&.footer {
		margin-top: 2em;
		text-align: left;
		background-color: #f0f0f0;
		padding: 0.8em;
		line-height: 1.25;
	}
}

#news {
	#art_edit_div {
		.meta-container {
			display: flex;
			fieldset {
				width: 33%;
				input {
					display: block;
				}
				label {
					display: block;
				}
			}
		}
	}
}