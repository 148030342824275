.shortcodes {
	&.greybox {
		padding: 16px;

		#articleview & {
			background: #f6f6f6;
			border-radius: 5px;
		}

		&>p:empty {
			display: none !important;
		}

		&>h2:first-of-type, &>h3:first-of-type {
			margin-top: 0 !important;
		}

		.two-column #articleview .markdown & .inline-image img {
			max-width: 641px;
		}

		.articlebox {
			background-color: #ececec;
		}
	}
}

.articlebox {
	background-color: #f6f6f6;
	font-size: 14px;
	display: flex;
	align-items: flex-start;
	position: relative;
	border-radius: 5px;
	height: 100px;
	overflow: hidden;
	margin: 1em 0;
	line-height: 1.5;

	& > a {
		display: flex;
		border: none;
		width: 100%;
		text-decoration: none;
		&:hover {
			text-decoration: none !important;
		}
	}

	figure.image {
		margin: 0;
		flex: 0 0 100px;
		height: 100px;

		img {
			position: relative;
			z-index: 2;
			display: block;
			width: 100%;
			height: auto;
			border-radius: 5px 0 0 5px;
		}
	}

	.text {
		flex: 1;
		margin: 8px;
		height: 84px;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.typ {
			font-weight: bold;
			flex: 1;
		}

		.title {
			margin: 0;
			font-size: 1em;
			color: #666;
			flex: 5;
			font-weight: bold;
		}

		.author {
			font-size: 0.75em;
			color: #999;
			flex: 1
		}
	}
}

@media only screen and (max-width: 420px) {
	.articlebox .text .author {
		display: none;
	}
}