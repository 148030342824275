.e_newsletters {
	margin-top: 30px;
	margin-bottom: 30px;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	align-items: center;
	font-size: 16px;
	color: #5f5f5f;

	.e_newsletters_button {
		display: inline-block;
		margin-right: 12px;
		padding: 0 12px;
		text-align: center;
		line-height: 28px;
		white-space: nowrap;
		font-size: 14px;
		color: @c_hiking_blue_dark;
		border-color: @c_hiking_blue_dark;
		border-width: 1px;
		border-style: solid;
		background: 0 0;
		cursor: pointer;
		&:hover {
			color: darken(@c_hiking_blue_dark, 10%);
			border-color: darken(@c_hiking_blue_dark, 10%);
		}
	}
}

.e_newsletters_b {
	flex: 0 0 100%;
	width: 100%;
	margin-top: 5px;
	font-size: 14px;
	color: lighten(@c_text_default, 33%);
}

.e_newsletters__loading {
	opacity: .6;
	pointer-events: none;
}