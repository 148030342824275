@charset "UTF-8";

.crmLogin {
	/*
	 * Copy of this file is also here: https://gitlab.projektn.sk/remp/web/-/blob/master/source/themes/dn-2/styles/login.less.
	 * Any changes here should be also applied there.
	 */

	@login_pad: 24px;
	@login_button: 46px;
	@login_width: 560px;
	@login_desktop: ~"only screen and (min-width: 700px)";
	@login_mobile: ~"only screen and (max-width: 699px)";
	@login_title: #000000;
	@login_background: #ffffff;
	@login_text: #484848;
	@login_mute: #9e9e9e;
	@login_border: #dbdbdb;
	@login_green: @c_hiking_green;
	@login_button_text: @c_text_default;
	@login_red: @c_hiking_green_dark;
	@login_invalid_background: fade(@c_hiking_green_dark, 5%);
	@login_sans: @font_sans;
	@login_serif: @font_sans;

	// Normalize
	* {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		border: 0;
		-ms-word-wrap: break-word;
		word-wrap: break-word;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-webkit-text-size-adjust: 100%;
	}
	html,
	body {
		font-weight: normal;
	}
	body {
		min-height: 100%;
	}
	main {
		display: block;
	}
	a {
		text-decoration: none;
		color: inherit;
	}
	img {
		display: block;
		max-width: 100%;
		height: auto;
	}
	img:not([src]) {
		visibility: hidden;
	}
	nav ul {
		list-style: none;
	}
	input,
	textarea,
	button,
	select,
	option {
		font-size: inherit;
		font-family: inherit;
		color: inherit;
		background: transparent;
	}
	button {
		cursor: pointer;
	}
	button:focus,
	input:focus,
	textarea:focus,
	select:focus,
	a:focus {
		outline: none;
	}


	// Create dark modal shade. Optional.
	.login_shade_inner {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: fade(black, 80%);
		z-index: 100000;
	}

	// Added to body prevents document from scrolling when modal is visible. Optional.
	.login_shade_outer {
		overflow: hidden;
	}

	// Login modal box. Optional.
	.login_modal_outer {
		position: relative;
		z-index: 100000;
		display: flex;
		flex-direction: column;
		width: @login_width;
		max-height: 90%;
		max-width: 90%;
		margin: 5% auto;
		background: @login_background;
	}

	// Padding of modal. Optional.
	.login_modal_inner {
		overflow-y: auto;
		@media @login_desktop {
			padding: 48px 96px;
		}
		@media @login_mobile {
			padding: 24px;
		}
	}

	// Modal close button, if modal is used, this is mandatory.
	.login_modal_close {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 100001;
		width: @login_button;
		height: @login_button;
		line-height: @login_button;
		text-align: center;
		font-size: 30px;
		color: black;
		opacity: 60%;
		&:hover, &:focus {
			opacity: 80%;
		}
	}

	// Actual login form, can be used standalone. Mix and match children for various cases.
	.login_form {
		line-height: 1.15;
		font-family: @login_serif;
		h1 {
			margin: 36px 0;
			text-align: center;
			font-size: 24px;
			font-weight: bold;
			color: @login_title;
		}
		p {
			margin: 36px 0;
			text-align: center;
			font-size: 16px;
			color: @login_text;
			line-height: 1.5;
			a {
				font-weight: bold;
				color: @login_red;
				&:hover, &:focus {
					color: darken(@login_red, 10%);
				}
			}
		}
		em {
			font-style: normal;
			color: black;
		}
		small {
			font-size: 14px;
			font-family: @login_sans;
			color: @login_mute;
			a {
				font-weight: normal;
				color: inherit;
				text-decoration: underline;
			}
		}
		hr {
			position: relative;
			height: 1px;
			overflow: visible;
			margin: 24px 0;
			border: 0; // nullify all other borders
			border-bottom: 1px solid @login_border;
			text-align: center;
			&:after {
				position: relative;
				top: -10px;
				display: inline-block;
				padding: 0 8px;
				line-height: 20px;
				font-family: @login_sans;
				font-size: 14px;
				color: @login_mute;
				background: @login_background;
				content: attr(title);
			}

			display: none;
		}
		input {
			display: block;
			width: 100%;
			padding-left: 32px;
			height: @login_button;
			text-align: left;
			font-family: @login_sans;
			font-size: 18px;
			font-weight: 400;
			border: 1px solid @login_border;
			color: @login_title;
			background-color: transparent;
			&:focus {
				border-color: @login_mute;
			}
			&.login_form_input_invalid {
				border-color: @login_red;
				background-color: @login_invalid_background;
			}
		}
	}

	// Wrappers around visible inputs
	.login_label {
		position: relative;
		display: block;
		margin: 8px 0;
		text-align: right;
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			width: 32px;
			height: @login_button;
			content: '';
			background-repeat: no-repeat;
		}
		&.login_form_input_invalid {
			border: 1px solid @login_red;
			background-color: @login_invalid_background;
		}
	}

	.login_label__password {
		&:before {
			background-image: url('data:image/svg+xml;utf8,<svg viewbox="0 0 10 14" xmlns="http://www.w3.org/2000/svg"><path fill="%239e9e9e" d="M8.75 5.6V4.2C8.75 1.8804 7.07107 0 5 0C2.92893 0 1.25 1.8804 1.25 4.2V5.6C0.559644 5.6 0 6.2268 0 7V12.6C0 13.3732 0.559644 14 1.25 14H8.75C9.44036 14 10 13.3732 10 12.6V7C10 6.2268 9.44036 5.6 8.75 5.6ZM2.5 4.2C2.5 2.6536 3.61929 1.4 5 1.4C6.38071 1.4 7.5 2.6536 7.5 4.2V5.6H2.5V4.2Z"/></svg>');
			background-size: 17px 20px;
			background-position: 14px 65%;
		}
		input {
			padding-right: @login_button;
		}
	}


	.login_label__email:before {
		background-image: url('data:image/svg+xml;utf8,<svg viewbox="0 0 12 10" xmlns="http://www.w3.org/2000/svg"><path fill="%239e9e9e" d="M10.8 0H1.2C0.537258 0 0 0.559644 0 1.25V8.75C0 9.44036 0.537258 10 1.2 10H10.8C11.4627 10 12 9.44036 12 8.75V1.25C12 0.559644 11.4627 0 10.8 0ZM10.8 4.375L6.99 7.15625C6.39543 7.58918 5.60457 7.58918 5.01 7.15625L1.2 4.375V3.0625L5.61 6.28125C5.84452 6.4508 6.15548 6.4508 6.39 6.28125L10.8 3.0625V4.375Z"/></svg>');
		background-size: 12px 10px;
		background-position: 12px 52%;
	}

	.login_label__checkbox {
		text-align: left;
		height: @login_button;
		display: inline-block;
		width: 100%;
		input {
			width: calc(@login_button / 2);
			vertical-align: middle;
			display: inline;
			margin: 0;
			position: relative;
			left: 4px;
		}
		p {
			display: initial;
			text-align: left;
			font-family: @login_serif;
			font-size: 14px;
			font-weight: normal;
			color: @login_mute;
			background-color: transparent;
			margin-left: 10px;
			&:focus {
				border-color: @login_mute;
			}
			a {
				color: inherit;
				text-decoration: underline;
				font-weight: normal;
			}
		}
	}

	.login_button_link {
		font-weight: bold;
		color: @login_red;
		font-size: 16px;
		line-height: 1.5;
		&:hover, &:focus {
			text-decoration: underline;
		}
	}

	.login_mode_switch_button {
		width: 100%;
		text-align: center;
		text-decoration: underline;
		color: @login_mute;
		font-size: 16px;
		&:hover, &:focus {
			color: @login_red;
		}
	}

	// Forgot email link
	.login_forgot {
		display: inline-block;
		margin-top: 8px;
		text-decoration: none;
		color: @login_mute;
		font-weight: 400;
		font-size: 16px;
		&:hover, &:focus {
			color: @login_red;
		}
	}

	// Login error message
	.login_error {
		display: block;
		margin: 16px 0;
		padding: 16px 16px 12px;
		font-size: 15px;
		border: none;
		color: @login_red;
		background: fade(@login_red, 5%);
		li {
			margin-left: 5px;
			list-style-type: none;
			margin-bottom: 1rem;
			background-position: left 4px;
			&:last-child {
				margin-bottom: 0;
			}
			span {
				vertical-align: top;
			}
		}
		li:before {
			content: '';
			width: 17px;
			height: 17px;
			display: inline-block;
			margin-right: 10px;
			background-size: cover;
			background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="%23bf001f" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"/></svg>');
		}
	}

	// Common button style
	.login_button {
		display: block;
		width: 100%;
		margin: 16px 0;
		line-height: @login_button;
		text-align: center;
		text-decoration: none;
		font-family: @login_sans;
		font-size: 18px;
		border: 1px solid;
		&:before {
			display: inline-block;
			margin: -2px 8px 0 0;
			vertical-align: middle;
			background-position: 50% 50%;
			background-repeat: no-repeat;
		};
		&:hover, &:focus {
			text-decoration: none;
		}
	}

	// Primary button style
	.login_button__green {
		border-color: @login_green;
		background: @login_green;
		color: @login_button_text;
		&:hover, &:focus {
			border-color: darken(@login_green, 5%);
			background: darken(@login_green, 5%);
		}
		&:disabled {
			border-color: lighten(@login_green, 15%);
			background: lighten(@login_green, 15%);
		}
	}

	// Secondary button style
	.login_button__white {
		border-color: @login_border;
		color: @login_title;
		&:hover, &:focus {
			background-color: darken(@login_background, 4%);
		}
	}

	// Override bootstrap
	.login_button__white {
		color: @login_title;
		&:hover, &:focus, &:link {
			color: @login_title;
		}
	}

	// Tertiary button style
	.login_button__trans {
		border-color: transparent;
		color: @login_mute;
		&:hover, &:focus {
			color: darken(@login_green, 5%);
		}
	}

	// Google login button style
	.login_button__google {
		display: none;
		&:before {
			width: 20px;
			height: 20px;
			background-image: url('data:image/svg+xml;utf8,<svg viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill="%234285F4" d="M19.6005 10.2271C19.6005 9.518 19.5369 8.83619 19.4187 8.18164H10.0005V12.0498H15.3823C15.1505 13.2998 14.4459 14.3589 13.3869 15.068V17.5771H16.6187C18.5096 15.8362 19.6005 13.2725 19.6005 10.2271Z"/><path fill="%2334A853" d="M10.0003 19.9999C12.7003 19.9999 14.964 19.1044 16.6185 17.5772L13.3867 15.0681C12.4912 15.6681 11.3458 16.0226 10.0003 16.0226C7.39578 16.0226 5.19124 14.2635 4.40487 11.8999H1.06396V14.4908C2.70942 17.759 6.09124 19.9999 10.0003 19.9999Z"/><path fill="%23FBBC05" d="M4.40454 11.9002C4.20454 11.3002 4.09091 10.6593 4.09091 10.0002C4.09091 9.3411 4.20454 8.70019 4.40454 8.10019V5.50928H1.06364C0.386364 6.85928 0 8.38655 0 10.0002C0 11.6138 0.386364 13.1411 1.06364 14.4911L4.40454 11.9002Z"/><path fill="%23EA4335" d="M10.0003 3.97727C11.4685 3.97727 12.7867 4.48182 13.8231 5.47273L16.6912 2.60455C14.9594 0.990909 12.6958 0 10.0003 0C6.09124 0 2.70942 2.24091 1.06396 5.50909L4.40487 8.1C5.19124 5.73636 7.39578 3.97727 10.0003 3.97727Z"/></svg>');
			content: '';
		}
	}

	// Apple login button style
	.login_button__apple {
		display: none;
		&:before {
			width: 17px;
			height: 20px;
			background-image: url('data:image/svg+xml;utf8,<svg viewbox="0 0 17 20" xmlns="http://www.w3.org/2000/svg"><path d="M11.5,3.2c0.6-0.7,1.1-1.8,1.1-2.8c0-0.1,0-0.3,0-0.4c-1.1,0-2.3,0.7-3.1,1.5C8.9,2.2,8.4,3.2,8.4,4.2c0,0.2,0,0.3,0,0.4 c0.1,0,0.2,0,0.3,0C9.6,4.6,10.8,4,11.5,3.2z M12.2,4.8c-1.6,0-2.9,0.9-3.7,0.9c-0.9,0-2-0.9-3.4-0.9c-2.6,0-5.2,2.1-5.2,5.9 c0,2.4,1,5,2.2,6.6c1,1.4,1.9,2.5,3.3,2.5c1.3,0,1.9-0.8,3.5-0.8c1.6,0,2,0.8,3.4,0.8c1.4,0,2.4-1.2,3.3-2.5c1-1.4,1.4-2.8,1.4-2.8 c-0.1,0-2.8-1.1-2.8-4c0-2.6,2.1-3.7,2.2-3.8C15,4.9,12.8,4.8,12.2,4.8z"/></svg>');
			content: '';
		}
	}

	// Site header logo
	.login_logo {
		display: block;
		width: 40px;
		height: 40px;
		margin: 0 auto -16px;
		border-radius: 8px;
		background: @login_red url('data:image/svg+xml;utf8,<svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="%23ffffff" d="M7.22033 4.04155H3.97546V19.9226H8.02293V23.1838H0.779948V19.9226H0.780028V4.04155H0.779785V0.780273H0.780028H3.97546H6.95766H8.02276H8.30038L15.5498 8.26181V12.691L7.22033 4.04155ZM23.2195 0.780273H23.2194H20.0241H15.5503V4.04155H20.0241V19.958H16.3159L7.95225 11.2731V15.7023L15.161 23.1418V23.2193H23.185V23.113H23.2195V0.780273Z"/></svg>');
		background-position-x: center;
		background-position-y: 8px;
		background-repeat: no-repeat;
		background-size: 24px;
	}

	// Checkmark header icon
	.login_done {
		position: relative;
		display: block;
		width: 64px;
		height: 64px;
		margin: 0 auto;
		border-radius: 50%;
		background: @login_green url('data:image/svg+xml;utf8,<svg viewbox="0 0 19 16" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke="%23ffffff" stroke-width="3" d="M1.34131 7.49983L6.79953 13.0822L17.659 1.91748"/></svg>') 50% 22px no-repeat;
		background-size: 19px;
		&:before {
			position: absolute;
			top: -4px;
			left: -4px;
			bottom: -4px;
			right: -4px;
			border: 1px solid @login_border;
			border-radius: 50%;
			content: '';
		}
	}

	// Show password toggle
	.login_show {
		position: absolute;
		top: 0;
		right: 0;
		width: @login_button;
		height: @login_button;
		background-size: 14px 10px;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		opacity: 50%;
		&:hover, &:focus {
			opacity: 100%;
		}
	}
	[type="password"]+.login_show {
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 14 10"><path fill="%23000000" d="M12.63,5A7.19,7.19,0,0,0,9.84,2.41a3.19,3.19,0,0,1,.44,1.65,3.16,3.16,0,0,1-1,2.32,3.26,3.26,0,0,1-4.64,0,3.16,3.16,0,0,1-1-2.32,3.19,3.19,0,0,1,.44-1.65A7.19,7.19,0,0,0,1.37,5,7.52,7.52,0,0,0,3.81,7.39,6,6,0,0,0,7,8.28a6,6,0,0,0,3.19-.89A7.52,7.52,0,0,0,12.63,5ZM7.35,2.18a.31.31,0,0,0-.1-.24A.34.34,0,0,0,7,1.83a2.12,2.12,0,0,0-1.57.66,2.14,2.14,0,0,0-.66,1.57.37.37,0,0,0,.1.25.35.35,0,0,0,.25.1.33.33,0,0,0,.25-.1.34.34,0,0,0,.11-.25A1.46,1.46,0,0,1,5.92,3,1.5,1.5,0,0,1,7,2.54a.34.34,0,0,0,.25-.11A.32.32,0,0,0,7.35,2.18ZM13.57,5a1,1,0,0,1-.15.51,7.76,7.76,0,0,1-2.76,2.7A7.16,7.16,0,0,1,7,9.22a7.08,7.08,0,0,1-3.66-1A7.74,7.74,0,0,1,.58,5.51a.94.94,0,0,1,0-1A7.74,7.74,0,0,1,3.34,1.8a7.08,7.08,0,0,1,7.32,0,7.74,7.74,0,0,1,2.76,2.69A1,1,0,0,1,13.57,5Z"/></svg>');
	}
	[type="text"]+.login_show {
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 14 10"><path fill="%23000000" d="M4.5,7.75l.57-1a3.32,3.32,0,0,1-1-1.17,3.36,3.36,0,0,1-.35-1.49,3.19,3.19,0,0,1,.44-1.65A7.19,7.19,0,0,0,1.37,5,7.25,7.25,0,0,0,4.5,7.75ZM7.35,2.18a.31.31,0,0,0-.1-.24A.34.34,0,0,0,7,1.83a2.12,2.12,0,0,0-1.57.66,2.14,2.14,0,0,0-.66,1.57.37.37,0,0,0,.1.25.35.35,0,0,0,.25.1.33.33,0,0,0,.25-.1.34.34,0,0,0,.11-.25A1.46,1.46,0,0,1,5.92,3,1.5,1.5,0,0,1,7,2.54a.34.34,0,0,0,.25-.11A.32.32,0,0,0,7.35,2.18ZM10,.78V.85Q9.23,2.23,7.69,5c-1,1.84-1.8,3.23-2.31,4.15L5,9.81a.23.23,0,0,1-.2.12,5.8,5.8,0,0,1-1-.52.21.21,0,0,1-.11-.2A2.5,2.5,0,0,1,4,8.57,7.59,7.59,0,0,1,2.11,7.3,8.73,8.73,0,0,1,.58,5.51a.94.94,0,0,1,0-1A8.69,8.69,0,0,1,3.36,1.77,7,7,0,0,1,7,.78,7.53,7.53,0,0,1,8.32.9l.4-.71a.21.21,0,0,1,.2-.12.36.36,0,0,1,.13,0l.23.11.24.14L9.75.5,9.9.59A.23.23,0,0,1,10,.78Zm.27,3.28a3.24,3.24,0,0,1-.57,1.86A3.29,3.29,0,0,1,8.17,7.13l2.06-3.68A4.55,4.55,0,0,1,10.28,4.06ZM13.57,5a1,1,0,0,1-.15.51,7.94,7.94,0,0,1-.8,1.06,8,8,0,0,1-2.54,2A7.08,7.08,0,0,1,7,9.22l.54-1a6.12,6.12,0,0,0,2.88-1A7.53,7.53,0,0,0,12.63,5a7.62,7.62,0,0,0-2.07-2.16L11,2a8.28,8.28,0,0,1,1.34,1.13,7.16,7.16,0,0,1,1.06,1.34A1,1,0,0,1,13.57,5Z"/></svg>');
	}
}