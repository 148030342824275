/*
 * =======================	
 *      ARTICLE LISTING 
 * =======================
 */

#articles {
    font-size: 9pt;
}
#articles .chata {
    display: block;
    border-bottom: 1px solid #a7c834;
    margin-bottom: 5px;
}
#articles .tura {
    display: block;
    border-bottom: 1px solid #a7c834;
    margin-bottom: 5px;
}
#articles .test {
    display: block;
    border-bottom: 1px solid #f5b335;
    margin-bottom: 5px;
}
#articles .rada {
    display: block;
    border-bottom: 1px solid #f5b335;
    margin-bottom: 5px;
}
#articles .cesta {
    display: block;
    border-bottom: 1px solid #5b9ee3;
    margin-bottom: 5px;
}
#articles .extra {
    display: block;
    border-bottom: 1px solid #5b9ee3;
    margin-bottom: 5px;
}
#articles div {
    height: 120px;
    overflow: hidden;
    position: relative;
}

#articles div.first-item {
    height: 160px;
    overflow: hidden;
}

#articles div .c1 {
    height: 160px;
    margin-right: 5px;
    float: left;
    overflow: visible;
    text-align: center;
}

#articles .chata .c1 {
    background: #a7c834;
}

#articles .tura .c1 {
    background: #a7c834;
}

#articles .test .c1 {
    background: #f5b335;
}

#articles .rada .c1 {
    background: #f5b335;
}

#articles .cesta .c1 {
    background: #5b9ee3;
}

#articles .extra .c1 {
    background: #5b9ee3;
}

#articles .c2 {
    font-size: 11pt;
    font-weight: bold;
}

#articles .tura .c2, #articleview .tura, #articles .tura .c_text a {
    color: #a7c834;
}

#articles .chata .c2, #articleview .chata, #articles .chata .c_text a {
    color: #a7c834;
}

#articles .test .c2, #articleview .test, #articles .test .c_text a {
    color: #f5b335;
}

#articles .rada .c2, #articleview .rada, #articles .rada .c_text a {
    color: #f5b335;
}

#articles .cesta .c2, #articleview .cesta, #articles .cesta .c_text a {
    color: #5b9ee3;
}

#articles .extra .c2, #articleview .extra, #articles .extra .c_text a {
    color: #5b9ee3;
}


#articles .c3 {
    font-size: 11pt;
    font-weight: bold;
    display: inline;
}
#articles div.first-item .c2 {
    font-size: 13pt;
}
#articles div.first-item .c3 {
    font-size: 13pt;
}
#articles .c3 a {
    color: #616161;
}
#articles a:hover {
    color: #333;
}
#articles .c4 {
    margin-top: 6px;
    margin-bottom: 6px;
    display: block;
    line-height: 1.25em;
    height: 6.25em;
    overflow: hidden;
}
#articles div.first-item .c4 {
    overflow: hidden;
    height: 7.55em;
    margin-top: 6px;
    margin-bottom: 7px;
}

#articles .c5 {
    font-size: 8pt;
    background: white;
}
#articles .c5 a {
    color: #bb3c27;
}
#articles .c6 {
    font-size: 8pt;
    position: absolute;
    right: 0px;
    bottom: 0px;
    background: white;
    text-align: right;
}
#articles div.first-item .c6 {

}

#articles .tura .c6 a {
    color: #a7c834;
}
#articles .chata .c6 a {
    color: #a7c834;
}
#articles .test .c6 a {
    color: #f5b335;
}
#articles .rada .c6 a {
    color: #f5b335;
}
#articles .cesta .c6 a {
    color: #5b9ee3;
}
#articles .extra .c6 a {
    color: #5b9ee3;
}
#articles .c7 {
    display: none;
}

#articles {
    #articles_tirazlist {
        height: 210px;
        .c_obrazok, .c1 {
            float: none;
            opacity: 0.1;
            height: 210px;
        }
        .c_typ, .c2, .c_nadpis, .c3, .c_perex, .c4   {
            bottom: 200px;
        }
        .c_nadpis, .c3 {
            color: inherit;
        }
        .c_perex, .c4 {
            color: inherit;
            height: 170px;
        }
    }
}

#articles.series {
}

img {
    border:none;
}

/*
 * =======================	
 *      ARTICLE VIEW 
 * =======================
 */

#articleview {
    display: block;
    clear: both;
    padding: 0px 20px 0px 20px;
    font-size: 14px;
    line-height: 1.5;
    max-width: calc(100vw - 40px);
}

#articleview .c1 {
    display: block;
    margin: 1.6em 0;
}

#articleview .c1 h1, #articleview .c1 > * {
	font-size: 1.6em;
	line-height: 1.25;
	margin-top: 0.1em;
	font-weight: bold;
	display: inline;
}
#articleview .c1 > *:after{
	content: "\0020";
}

#articleview .c2 {
    display: block;
    margin: 0.5em 0;
    line-height: 1.25;
}
#articleview .c2 a {
    color: #bb3c27;
    display: block;
    padding-top: 0.9em;
}
#articleview .c2 .time {
    display: inline-block;
    font-size: 0.9em;
}
#articleview .c2 .buttons {
	margin-top: 1em;
}

#articleview .c2 > i {
	font-size: 0.9em;
}

#articleview .c2 > i:before {
	content: '\2022\00a0';
	color: #ccc;
}

#articleview .c2 img {
    float: left;
    margin-right: 10px;
    width: 72px;
    height: 72px;
    border-radius: 36px;
}
#articleview .c3 {
    display: block;
    margin-top: 1em;
    font-size: 1.15em;
}

#articleview {
    .c_before_text, .c_after_text {
        display: block;
    }
}

#articleview .c_text {
    display: block;
    h2 {
        font-size: 1.2em;
        line-height: 1.5;
    }
    a {
        color: #bb3c27;
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
    .footnote {
        color: #666;
        font-style: italic;
        margin-bottom: 2em;
        font-size: 0.8em;
    }
}


#articleview table.parsed tr {
    border-bottom: 1px solid #ccc;
}

#articleview table.parsed td {
    border-right: 1px solid #ccc;
    padding: 2px;
    text-align: center;
}

#articleview table.parsed {
    border-collapse: collapse;
    border: 1px solid #ccc;
}

#articleview table.parsed td:first-child {
    text-align: left;
}

.blokoranzovy_ul.notpublished {
    background-color: #ffffee;
}

#articleview .c_text.notpublished.set {
    line-height: 1.5em;
    height: 1.5em;
    overflow: hidden;
}

#articleview .notpublished.set h3 {
    margin-top: 2em !important;
}

#articleview .notpublished.set tt {
    font-size: 0.8em;
}

#articleview .notpublished.set a {
    color: #333;
}
#articleview .notpublished.set a.pr {
    color: #d3604d;
}
#articleview .notpublished.set a.deti {
    color: #169e00;
}
#articleview .notpublished.set a.vybava {
    color: #1600ff;
}

#articleview .notification.footer {
	margin-top: 2em;
	text-align: left;
	background-color:#f0f0f0;
	padding: 0.8em;
	line-height: 1.25;
}


@media only screen and (max-width: 493px) {
	#articleview {
		width: 100vw;
		font-size: 18px;
	}
}

/*
 * =======================
 *      ARTICLE FACTS
 * =======================
 */

#articlefacts {
    font-size: 9pt;
    margin-bottom: 3px;
}
#articlefacts .caption {
    font-family: HelveticaNeue-CondensedBlack, Impact, "Arial Narrow", sans-serif;
    font-size: 12pt;
    text-transform: uppercase;
    letter-spacing: 1pt;
    display: block;
    background-color: #f5b335;
    color: white;
    padding: 5px 0px 2px 5px;
    border-bottom: 1px solid white;
}
#articlefacts .caption .comment {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 8pt;
    text-transform: none;
    letter-spacing: normal;
}
#articlefacts .caption .comment a {
    color: white;
    text-decoration: none;
}

#articlefacts_ul a {
    color: #bb3c27;
}
#articlefacts_ul a:hover {
    text-decoration: underline;
}
#articlefacts_ul {
    background-color: #f7d135;
    padding: 0px;
    margin: 0px;
}
#articlefacts_ul p {
    margin: 0;
}
#articlefacts_ul li {
    list-style: none;
    border-bottom: 1px solid white;
    padding: 5px;
    color: #bb3c27;
}
#articlefacts_ul li ul {
    padding: 3px 0px 0px 0px;
    margin: 0px;
}
#articlefacts_ul li ul li {
    border: none;
    padding: 0px;
    color: #333;
    font-size: 8pt;
}

/*
 * =======================
 *     ARTICLE RELATED
 * =======================
 */


#articlesrelated {
    font-size: 9pt;
    margin-bottom: 3px;
}
#articlesrelated .caption {
    font-family: HelveticaNeue-CondensedBlack, Impact, "Arial Narrow", sans-serif;
    font-size: 12pt;
    text-transform: uppercase;
    letter-spacing: 1pt;
    display: block;
    background-color: #f5b335;
    color: white;
    padding: 5px 0px 2px 5px;
    border-bottom: 1px solid white;
}
#articlesrelated a {
    color: #333;
}
#articlesrelated a:hover {
    color: #bb3c27;
}
#articlesrelated_ul {
    background-color: #f7d135;
    padding: 0px;
    margin: 0px;
}

#articlesrelated_ul li {
    list-style: none;
    border-bottom: 1px solid white;
    padding: 5px;
    color: #bb3c27;
}
#articlesrelated_ul li ul {
    padding: 3px 0px 0px 0px;
    margin: 0px;
}
#articlesrelated_ul li ul li {
    border: none;
    padding: 0px;
    color: #333;
    font-size: 8pt;
}

/*
 * =======================
 *     ARTICLE NAVIGATE
 * =======================
 */

#article_navigate a {
    display: block;
    padding: 3px 8px 3px 8px;
    margin-left: 3px;
    background-color: #999;
    color: white;
    float: right;
}
#article_navigate a:hover {
    background-color: #666;
}
#edit_caption_div{
    overflow: hidden;
    width:100%;
}
#edit_caption{
    font-family: HelveticaNeue-CondensedBlack, Impact, "Arial Narrow", sans-serif;
    font-size: 12pt;
    text-transform: uppercase;
    letter-spacing: 1pt;
    display: block;
    color: white;
    background-color: rgb(180,180,180);
    padding: 5px 0px 2px 5px;
    border-bottom: 1px solid white;
}

/*
 * =======================
 *   ARTICLE PRINT SETUP
 * =======================
 */


#articleprintsetup {
    font-size: 9pt;
    margin-bottom: 3px;
}

#articleprintsetup form {
    padding: 0px;
    margin: 3px 3px 3px 3px;
}
#articleprintsetup .caption {
    font-family: HelveticaNeue-CondensedBlack, Impact, "Arial Narrow", sans-serif;
    font-size: 12pt;
    text-transform: uppercase;
    letter-spacing: 1pt;
    display: block;
    background-color: #f5b335;
    color: white;
    padding: 5px 0px 2px 5px;
    border-bottom: 1px solid white;
}
#articleprintsetup_ul {
    background-color: #f7d135;
    padding: 0px;
    margin: 0px;
}
#articleprintsetup_ul li {
    list-style: none;
    border-bottom: 1px solid white;
    margin: 0px;
    color: #bb3c27;
    padding: 2px 2px 2px 2px;

}

#articleprintsetup_ul label {
    border: none;
    padding: 0;
    color: #333;
    font-size: 12px;
    margin-right: 5px;
}

#articleprintsetup_ul .button {
    display: block;
    float: right;
    width: 50px;
    text-align: center;
    background-color: #f7d135;
    padding: 2px;
    margin: 2px;
    border: 1px solid #CA870B;
    color: #CA870B;
}
#art_edit_div fieldset {
    margin: 2px;
    padding: 10px;
    font-size: 8pt;
    color: black;
    background-color: #F1F1F1;
    border: 1px solid #DBDBDB;
}

#art_edit_div .error {
    color: red;
    font-size: 1.2em;
}

#art_edit_div .correct {
    color: #009900;
    font-size: inherit;
}

#art_edit_div input.error {
    font-size: inherit;
    background-color: #ffd8d8;
}

#art_memoText {
	display: none;
	padding: 5px;
	width:   400px;
	font-size: 12px;
	border: black solid 1px;
	font-family: Arial CE, Tahoma;
	position: absolute;
  background-color: #e0e0e0;
  color: #000000;
}

/*
 * =======================
 *     TAG RELATED
 * =======================
 */

.tag-related {
    background-color: #f7d639;
    color: #333;
    font-size: 8pt;
    padding: 0px;
    margin: 0px;
    margin-bottom: 4px;
    clear: both;
}
.tag-related .caption {
    font-family: HelveticaNeue-CondensedBlack, Impact, "Arial Narrow", sans-serif;
    font-size: 12pt;
    text-transform: uppercase;
    letter-spacing: 1pt;
    display: block;
    background-color: #f5b335;
    color: white;
    padding: 5px 0px 2px 5px;
}
.tag-related .caption .comment {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 9pt;
    letter-spacing: normal;
    text-transform: none;
}

.tag-related-list .nodisp {
    display: none;
}
.tag-related-list .menu {
    padding: 5px;
    color: #bb3c27;
    font-size: 9pt;
    border-top: 1px solid white;
    clear: both;
}
.tag-related-list .item, .tag-related-list .more {
    padding-left: 5px;
    padding-right: 5px;
    clear: both;
    position: relative;
}

.tag-related-list .item {
    height: 60px;
    position: relative;
}

.tag-related-list .more a {
    line-height: 25px;
}

.tag-related-list .c_picture {
    float: left;
    margin-right: 5px;
}
.tag-related-list img {
    width: 57px;
    height: 57px;
    border: 0;
}
.tag-related-list .item .c_title {
    font-size: 12px;
    padding: 8px 0 2px 0;
    overflow: hidden;
}
/*.tag-related-list .item .c_info {
    position: absolute;
    bottom: 5px;
    left: 67px;
}*/
.tag-related-list a {
    color: #333;
}
.tag-related-list a:hover {
    color: #bb3c27;
}

/*
 * =======================
 *     ARTICLE SERIES
 * =======================
 */

.article-series .caption {
    background-color: inherit;
    border-bottom: 1px solid white;
}

.article-series-list > div {
    border-bottom: 1px solid white;
    overflow: hidden;
    font-size: 9pt;
    padding: 5px 5px 3px 5px;
    height: 14px;
    overflow: hidden;
}

.article-series-list .c_title {
    font-size: 9pt;
    display: inline;
    line-height: 13px;
    height: 13px;
    overflow: hidden;
}

.article-series-list .c_title a, .article-series-list .c_title a:hover, .article-series-list .c_title a:link, .article-series-list .c_title a:visited {
    color: #bb3c27;
}

.article-series-list .c_last_article {
    font-size: 8pt;
    display: inline;
}


/*
 * =======================
 *      DATE RELATED
 * =======================
 */

.date-related .content {
    position: relative;
    overflow: hidden;
    width: 240px;
}

.date-related .content .item {
    border-bottom: 1px solid white;
    position: relative;
    height: 30px;
    padding: 5px 4px 3px 4px;
}

.date-related .content .item.nodisp {
    display: none;
}

.date-related .content .item.first-item {
    border-bottom: 1px solid white;
    position: relative;
    padding: 0px;
    height: 160px;
    overflow: hidden;
}
.date-related .content .item.first-item .c_picture img {
    width: 245px;
    height: 160px;
}
.date-related .content .item.first-item .c_title {
    position: absolute;
    width: 232px;
    font-size: 11pt;
    font-weight: bold;
    padding: 10px 4px 18px 4px;
    bottom: 0px;
    left: 0px;
    vertical-align: bottom;
    background: none !important;
    text-shadow: 0 0 3px rgba(0, 0, 0, .75);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0.3) 100%) !important;
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(60%, rgba(0, 0, 0, 0.3)), color-stop(100%, rgba(0, 0, 0, 0.3))) !important;
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0.3) 100%) !important;
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0.3) 100%) !important;
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0.3) 100%) !important;
    /* IE10+ */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0.3) 100%) !important;
    /* W3C */
    /* filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#59000000', GradientType=0 ) !important;*/
    /* IE6-9 */
}
.date-related .content .item.first-item .c_title a {
    white-space: normal;
    color: #f0f0f0;
}
.date-related .content .item.first-item .c_title a .typ {
    color: #a7c834;
}
.date-related .content .item.first-item .c_title a .typ.tura {
    color: #a7c834;
}
.date-related .content .item.first-item .c_title a .typ.chata {
    color: #a7c834;
}
.date-related .content .item.first-item .c_title a .typ.test {
    color: #f5b335;
}
.date-related .content .item.first-item .c_title a .typ.rada {
    color: #f5b335;
}
.date-related .content .item.first-item .c_title a .typ.cesta {
    color: #5b9ee3;
}
.date-related .content .item.first-item .c_title a .typ.extra {
    color: #5b9ee3;
}

.date-related .content .item.first-item.skialp .c_title a .typ, .date-related .content .item.first-item.bezky .c_title a .typ, .date-related .content .item.first-item.beh .c_title a .typ {
    color: #5b9ee3;
}

.date-related .content .item.first-item .c_info, .date-related .content .item.first-item .c_locality {
    position: absolute;
    bottom: 4px;
    color: rgba(255,255,255,0.75);
}

.date-related .content .item .c_title {
    font-size: 10pt;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
}

.date-related .content .item .c_title a {
    white-space: nowrap;
    text-overflow: ellipsis;
}

.date-related .content .item .c_title a .typ {
    color: #444;
}

.date-related .content .item .c_locality, .date-related .content .item .c_info {
    position: absolute;
    bottom: 3px;
    font-size: 7pt;
    letter-spacing: 0.05em;
}
.date-related .content .item .c_locality {
    right: 4px;
}
.date-related .content .item .c_info {
    left: 4px;
}

.date-related .content .more {
    padding: 4px 4px 3px 4px;
    text-transform: uppercase;
    text-align: right;
    font-size: 8pt;
    font-weight: bold;
}

.blokoranzovy.date-related a {
    color: #bb3c27;
}

.blokoranzovy.date-related a:hover {
    color: black;
}
.blokoranzovy.date-related .content .item .c_title {
    color: #bb3c27;
}


/*
 * =======================
 *     UNDER ARTICLE
 * =======================
 */

.underarticle .first-item {
    display: none;
}
.underarticle {
    padding: 0px 20px;
}
.underarticle div {
    border: none !important;
    position: relative;
    height: 100px !important;
    margin-bottom: 20px !important;
}
.underarticle > span.caption {
    display: block;
    margin: 2em 0 1em 0 !important;
    font-size: 1.2em;
    font-weight: bold;
}
.underarticle .c_obrazok {
    background: none !important;
}
.underarticle .c_perex {
    text-overflow: ellipsis;
}
.underarticle .c_datum {
    position: absolute;
    display: block;
    bottom: 0px;
    left: 0px;
    color: white;
    width: 90px;
    padding: 10px 5px 2px 5px;
    background: none !important;
    text-shadow: 0 0 3px rgba(0, 0, 0, .75);
    /*background: -webkit-linear-gradient(rgba(255,255,255,0) 20%, rgba(0,0,0,0.35) 100%) !important; */

    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,0.35) 100%) !important; /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(20%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.35))) !important; /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 20%,rgba(0,0,0,0.35) 100%) !important; /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 20%,rgba(0,0,0,0.35) 100%) !important; /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 20%,rgba(0,0,0,0.35) 100%) !important; /* IE10+ */
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 20%,rgba(0,0,0,0.35) 100%) !important; /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#59000000',GradientType=0 ) !important; /* IE6-9 */
}
.underarticle .c_datum a {
    display: none;
}
.underarticle .c_viac {
    display: none;
}


/*
 * =======================
 *     QUICK EDIT FORM
 * =======================
 */


.aqe {
    position: relative;
    clear: both;
    overflow: hidden;
}
.aqe-header {
    background-color: #9b9b9b;
    color: white;
    padding: 5px 10px;
}
.aqe-title {
    font-size: 1.1em;
}
.aqe-label {
    font-size: 0.9em;
    font-weight: bold;
}
.aqe-left {
    width: 200px;
    float: left;
}
.aqe-mid {
    width: 230px;
    float: left;
}
.aqe-right {
    position: absolute;
    right: 20px;
}

/*
 * =======================
 *     ADDING PICTURES
 * =======================
 */

/* div - adding pictures - - - - - - - - - - - - - - */

.art-add-pic .fileinput-button {
    width: 160px !important;
    /*
    line-height: 30px;
    text-align: center;
    font-size: 1.3em;
    color: buttontext;
    background-color: buttonface;
    border: 2px outset buttonface;
    padding: 1px 8px 2px 8px;
    */
    float: none;
}
.art-add-pic .name {
    font-size: 1em;
    position: relative;
    width: 100%;
}
.art-add-pic .files .progress {
    width: 400px;
}
.art-add-pic .files input {
    width: 494px;
}
.art-add-pic .files input.narrow {
    width: 80px;
}
.art-add-pic .files input.narrow2x {
    width: 160px;
}
.art-add-pic img {
    width: 100px;
}
.art-add-pic td {
    text-align: left;
    width: 400px;
}
.art-add-pic td.preview {
    text-align: left;
    width: 110px;
    height: 106px;
}
.art-add-pic button {
    font-size: 1em;
}
.art-add-pic td.start, td.cancel, td.delete{
    width: 5.7em;
}
#art-add-pic-table .ui-state-highlight {
    height: 102px;
}

#art-add-pic-table tr:first-child > td.name > span:first-child::before {
    content: "Táto foto bude titulná – ";
    color: #bb3c27;
}

#art-add-pic-dropzone {
    background: white;
    width: 704px;
    text-align: center;
    font-weight: normal;
    padding: 20px 0px;
    margin: 2px 0px;
    border: 4px dashed #ccc;
}
#art-add-pic-dropzone.in {
    border-color: #f3ba40;
    color: #bb3c27;
    padding: 60px 0px;
    /*line-height: 200px;*/
}

#art-add-pic-dropzone.hover {
    border-color: #a7c834;
    color: #7e9920;
}
#art-add-pic-dropzone.fade {
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    opacity: 1;
}

/*
 * =======================
 *     ARTICLE ADD FORM
 * =======================
 */

.article-edit-form, #art-addpict-form {
    /*margin-bottom: 10em;*/
    margin-bottom: 10px;
}

.article-edit-form fieldset:last-child {
    border: none;
}

.article-edit-form fieldset {
    margin-bottom: 1px;
    border: none;
    border-top: 1px solid #bbb;
    background-color: initial;
    position: relative;
    padding: 10px 5px 20px 5px;
}

.article-edit-form legend {
    font-size: 11px;
    text-transform: uppercase;
    color: #999;
}

.article-edit-form label {
    margin-top: 1em;
    display: block;
}

.article-edit-form .inline {
    margin-top: inherit;
    display: inline;
    width: inherit;
}

.article-edit-form input, .article-edit-form textarea, .article-edit-form select {
    border: 1px solid #ccc;
    max-width: 650px;
    width: 50%;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

}

.article-edit-form input:focus, .article-edit-form textarea:focus, .article-edit-form select:focus {
    border-color: rgba(82, 168, 236, .8);
    outline: 0;
    outline: thin dotted\9;
    -webkit-box-shadow: 0 0 8px rgba(82, 168, 236, .6);
    box-shadow: 0 0 8px rgba(82, 168, 236, .6);
}

.article-edit-form input[type="button"],  .art-add-pic .fileinput-button {
    width: inherit;
    cursor: pointer;

    -webkit-transition: background-color ease-in-out .15s;
    -o-transition: background-color ease-in-out .15s;
    transition: background-color ease-in-out .15s;

    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;

    color: #333;
    background-color: #fff;

    #articleview div & {
        padding: 6px 12px;
    }

}

.art-add-pic .fileinput-button input {
    width: 100%;
}

.article-edit-form input[type="button"]:hover,  .art-add-pic .fileinput-button:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.article-edit-form input, .article-edit-form textarea {
    padding: 3px 6px;
    font-size: 1.1em;
}

.article-edit-form select {
    font-size: 1em;
}
.article-edit-form select[multiple] {
    font-size: 0.9em;
    padding: 3px;
}
.article-edit-form input[name="display"] {
    display: none;
}

.article-edit-form input.wide, .article-edit-form textarea.wide {
    width: 100%;
}
.article-edit-form input.narrow, .article-edit-form textarea.narrow {
    width: 25%;
}

.article-edit-form input[type="radio"] {
    width: inherit;
}

#art_edit_div input[name="title"] {
    font-size: 1.5em;
    line-height: 1.5em;
    width: 98%;
    padding: 0px 5px;
    font-weight: bold;
}

#art_edit_div textarea {
    font-size: 1.2em;
    line-height: 1.4em;
    padding: 5px;
}

textarea.markdown, #art_edit_div textarea.markdown {
    font-family: "Ubuntu Mono", Menlo, Monaco, "Courier New", monospace;
    font-size: 1.3em;
    line-height: 1.2em;
    padding: 5px;
}

/*
 * =======================
 *     ARTICLE MARKDOWN
 * =======================
 */

.markdown span.code {
    font-family: "Ubuntu Mono", Menlo, Monaco, "Courier New", monospace;
    overflow-wrap: anywhere;
}

pre {
    font-family: "Ubuntu Mono", Menlo, Monaco, "Courier New", monospace;
    overflow-wrap: anywhere;
}

.markdown table {
    border-collapse: collapse;
    border: 1px solid #ccc;
}

.markdown tr {
    border-bottom: 1px solid #ccc;
}

.markdown th, .markdown td  {
    padding: 2px 4px;
}

.markdown th {
    padding: 4px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.markdown td {
    border-right: 1px solid #ccc;
}

.markdown hr {
    border: none;
    border-bottom: 1px solid #ccc;
}

.markdown .footnotes {
    font-size: 0.9em;
}

.markdown .footnotes::before {
    content: "Poznámky: ";
}

.markdown blockquote {
    margin: 0 1em 1em 0;
    padding: 5px 5px 5px 12px;
    border-left: 3px solid #f5b335;
    color: #333;
    font-size: 1.1em;
    font-style: italic;
    line-height: 1.5em;
    font-family: Georgia, serif;
}
.markdown blockquote blockquote {
    border-color: #ccc;
}
.markdown blockquote p {
    margin: 0.5em 0;
}
.markdown blockquote em {
    font-size: 0.7em;
    color: #666;
}
.markdown blockquote h1, .markdown blockquote h2, .markdown blockquote h3, .markdown blockquote h4 {
    font-weight: normal;
    font-style: normal;
}

.markdown img {
    max-height: 650px;
    max-width: 650px;
    height: auto;
    width: auto;
    border-radius: 3px;
    .mobile & {
        max-width: calc(100vw - 24px);
    }
}

.embed {
    margin: 1.5em 0;
    padding: 12px;
    background-color: rgba(234,234,234,.5);
    color: #333;
    font-size: 1.1em;
    line-height: 1.5em;
    font-family: Georgia, serif;
}
.embed .title {
    margin-bottom: 10px;
}
.embed .footer {
    font-size: 0.8em;
    margin: 5px 0 0 0;
}

/*
 * =======================
 *     ARTICLE PREVIEW
 * =======================
 */

#art_edit_div {
    .md-editor {
        #articleview.md-preview.markdown {
            padding: 0 20px 0 20px;

            h1, h2 {
                font-size: 1.2em;
                line-height: 1.33;
                color: #444;
            }
        }

        .md-input {
            padding: 5px;
        }
    }
}

/*
 * ARTICLE PREVIEW END
 */

#articleview .markdown h3 {
    font-size: 1.1em;
}

#articleview .markdown h4 {
    font-size: 1em;
}

#articles.series > div.first-item {
    height: 120px;
}

#before_main .series {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 350px;
    position: relative;
}

#before_main .series h1 {
    margin: 0;
    color: white;
    text-align: left;
    /*text-shadow: 0px 0px 5px rgba(0,0,0,1);*/
    font-size: 20px;
    line-height: normal;
}
#before_main .series h1 span {
    color: #a7c834;
}

#before_main .series .desc {
    margin: 0;
    padding: 10px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-color: rgba(0,0,0,0.7);
    color: #ccc;
    font-size: 1em;
    line-height: 1.3em;
}

#before_main .series .desc p {
    margin: 0;
}

.ui-datepicker-week-end {
    /*background-color: #FFAAAA;*/
}
.art-calendar-occ a {
    /*background-color: #7777FF !important;
    background-image :none !important;*/
    color: #3333FF !important;
    font-weight:bold !important;
}
.art-calendar-pr a {
    background-color: #FFBBBB !important;
    background-image :none !important;
    /*color: #FF7777 !important;*/
    font-weight:bold !important;
}

.article-series.members {
    background-color: #f3f3f3;
    margin: 0 60px 10px 0;
    padding: 10px;
}
.article-series.members h3 {
    margin: 0;
    font-size: 10pt;
}
.article-series.members h3 .type {
    color: #f5b335;
}
.article-series.members h3 .comment {
    font-weight: normal;
    font-size: 0.8em;
}
.article-series.members p {
    margin: 5px 0 0 0;
}
.article-series.members select {
    border-radius: 5px;
    border: 1px solid #999;
    height: 20px;
    background-color: #f3f3f3;
}
.article-series.members a {
    color: #333;
    height: 14px;
    vertical-align: top;
    display: inline-block;
    font-size: 11px;
    line-height: 12px;
    border: 1px solid #999;
    padding: 3px 5px 1px 5px;
    border-radius: 5px;
}
.article-series.members a.next {
    margin-left: 3px;
}
.article-series.members a.prev {
    margin-right: 3px;
}
.article-series.members a:hover {
    background-color: white;
}

.blokoranzovy .pr  {
    color: #d3604d;
}
.blokoranzovy .deti  {
    color: #169e00;
}
.blokoranzovy .vybava  {
    color: #1600ff;
}

/*
 * =======================
 *     ARTICLE FILTER
 * =======================
 */

#arf-form {
    input[type='text'], input[type='number'] {
        width: 45px;
    }
}
.arf-notice {
    padding: 10px;
    font-size: 14px;
    line-height: 1.33;
}
.arf-default-feed.fade {
    opacity: 0.3;
}