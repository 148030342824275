.obrazky.simple {
    margin-top: 1em;
    border: none !important;
}

.obrazky.simple .member {
    display: inline-block;
    height: auto;
    
}

.obrazky.simple img.obrazok {
    border-radius: 2px;
    width: 57px;
}

.obrazky-add {
    .fileinput-button {
        padding: 7px 2px 2px 2px;

        .button-style {
            float: initial;
            display: inline-block;
        }

        .filename {
            display: none;
        }
    }

    td {
        .preview, .name, .delete {
            background-color: transparent;
        }
    }

    .well {
        border-radius: 0px;
        padding: 5px;
        min-height: inherit;
    }

    .dropzone {
        background: white;
        text-align: center;
        font-weight: normal;
        padding: 20px 0;
    }

    .dropzone.in {
        border-color: #f3ba40;
        color: #bb3c27;
        padding: 60px 0 !important;
    }

    .dropzone.hover {
        border-color: #a7c834;
        color: #7e9920;
    }

    .dropzone.fade {
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        opacity: 1;
    }

    .table-files {
        border-collapse: collapse;
    }

    #forum_post_add & {
        border-bottom: none;
        padding: 0;
        margin: -4px 0 5px 0;
        background-color: rgba(255,255,255,0.5);
        width: 226px;

        div {
            padding: 0;
            border: none;
        }

        .dropzone {
            color: @c_text_default;
            font-size: inherit;
        }

        td.name {
            input {
                width: 156px;
            }
            .progress {
                width: 162px;
            }
        }
    }

    #news & {
        .table-files {
            width: 100%;

            .template-download {
                .preview {
                    width: 80px;

                    img {
                        width: 75px;
                        height: auto;
                    }
                }

                .name {
                    display: flex;
                    align-items: center;
                    gap: 5px 10px;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    input[type="text"] {
                        flex-grow: 4;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.obrazky.newsedit {
    .member {
        display: flex;
        align-items: center;
        gap: 5px;
        min-height: 45px;
        img.obrazok {
            width: 75px;
        }
    }
}